.hero__container {
  position: relative;
  width: 100vw;
  max-width: 100%;
}
.hero__container__bg {
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-height: 400px;
}
.hero__box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  font-size: 32px;
  line-height: 1;
  color: white;
  text-transform: uppercase;
  text-shadow: 3px 4px 8px rgba(0, 0, 0, 0.8);
  font-weight: 400;
  padding: 16px 32px;
}
.hero__box__account {
  color: black;
}
