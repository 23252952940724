.sign-in-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  height: 100%;
}

.sign-in-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
}

.sign-in-form {
  width: 250px;
  text-align: center;
  white-space: pre-line;
}
