.App {
  height: 100%;
  flex: 1;
  background-color: #0d1a42;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.white {
  background-color: #fff;
  color: #333;
  justify-content: flex-start;
}

.flex-center,
.logo,
.event-picture,
.thumbnail,
.ab-modal-root,
.logo-container,
.centered-content-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-content-root {
  flex: 1;
}

.SnackbarItem-action .MuiIconButton-root {
  color: inherit;
}

.nav-root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.page-root {
  flex: 1;
  padding: 32px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small-margin-top {
  margin-top: 8px !important;
}

.margin-top {
  margin-top: 16px !important;
}

.margin-bottom {
  margin-bottom: 16px !important;
}

.big-margin-bottom {
  margin-bottom: 32px !important;
}

.margin-left {
  margin-left: 8px !important;
}

.partner-card {
  height: 100%;
  min-width: 250px;
}

.partner-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 32px;
  height: calc(100% - 150px);
}

.logo-container {
  width: 100%;
  height: 150px;
}

.logo {
  width: 126px;
  height: 126px;
  border-radius: 63px;
}

.event-picture {
  width: 350px;
  height: 200px;
}

.thumbnail {
  width: 80px;
  height: 80px;
}

.logo,
.event-picture,
.thumbnail {
  background-color: #0d1a42 !important;
  text-align: center;
}

.clickable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.ab-modal-root {
  height: 100%;
}

.ab-modal-paper {
  padding: 32px;
  min-height: 450px;
  max-height: 850px;
  min-width: 450px;
  max-width: 1050px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
}

.info-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-evenly;
}

.row {
  display: flex;
  flex-direction: row;
  align-self: stretch;
}

.space-between {
  justify-content: space-between;
  align-items: center;
}

.list-card {
  align-self: stretch;
  margin-bottom: 16px;
}

.list-card.selected {
  border: 2px solid #000;
}

.list-card-root:not(.selected) {
  background-color: #f7f8f8;
}

.card-root {
  padding: 16px;
}

.center {
  text-align: center;
  align-self: center;
}

.returns {
  white-space: pre-line;
}

.article {
  max-width: 436px;
}

.article img {
  max-width: 404px;
}

.article iframe {
  max-width: 404px;
}

.article figure {
  margin: 0;
}

.article h1 {
  font-size: medium;
}

.questions-root ul {
  margin: 0;
}

.quill > .ql-toolbar:first-child {
  display: none !important;
}

.quill > .ql-container {
  font-size: inherit !important;
}

.ql-editor {
  height: inherit !important;
}

.center-card {
  background-color: #fff;
  color: #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  min-width: 280px;
  padding: 32px;
  margin: 32px;
  border-radius: 16px;
  justify-self: center;
  max-width: 500px;
}
