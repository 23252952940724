.tickets__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32;
  padding: 16px;
}

.tickets__avantagesButton {
  margin-bottom: 32px;
}

.tickets__accordion {
  width: 800px;
}

.tickets__match__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 16px;
}

.tickets__match__container__logo {
  width: 60px;
  text-align: center;
}

.tickets__match__container__infos {
  flex: 1;
  margin: 0 0 0 16px;
}

.tickets__match__container__infos__title {
  font-size: 20px;
  margin: 0;
}

.tickets__match__container__tickets {
  margin-right: 16px;
}

.ticket__title {
  font-size: 16px;
}

.ticket__subtitle {
  font-size: 16px;
}

.categories {
  display: flex;
  justify-content: center;
}
.categories__accordion {
  width: 700px;
}

.categories__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 16px;
}

.categories__container__category {
  font-size: 20px;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .tickets__match__container {
    margin-right: 4px;
  }

  .tickets__match__container__logo {
    width: 50px;
  }
  .tickets__accordion {
    width: 100%;
    margin: 0 8px;
  }

  .tickets__match__container__infos__title {
    font-size: 11px;
    line-height: 12px;
    width: 100%;
  }
  .tickets__match__container__infos__body {
    line-height: 12px;
    font-size: 11px;
    margin-top: 4px;
  }
  .tickets__match__container__tickets {
    margin-right: 0;
    margin-left: 4px;
    font-size: 12px;
  }

  .ticket__title {
    font-size: 12px;
  }

  .ticket__subtitle {
    font-size: 12px;
  }

  .categories__accordion {
    width: 300px;
  }
  .categories__container__category {
    font-size: 12px;
  }
  .categories__container__tickets {
    font-size: 12px;
  }
}
