.banner {
  width: 280px;
  position: relative;
  height: 150px;
  margin-bottom: 8px;
}

.banner__picture {
  border-radius: 10px;
  height: 150px;
  width: 280px;
  object-fit: cover;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}

.banner__overlay {
  position: absolute;
  border-radius: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(13, 26, 66, 0.5) 100%);
}

.banner__contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  z-index: 10;
}

.banner__title {
  position: absolute;
  font-size: 20px;
  line-height: 1;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  text-align: right;
  margin: 0;
}
