.card__container {
  background-color: white;
  border-radius: 15px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding: 32px;
}

.card__logo {
  width: 200px;
}

.card__title {
  font-size: 32px;
  font-weight: 500;
  width: 300px;
  text-align: center;
  margin: 0 0 24px 0;
  line-height: 32px;
}

.card__subtitle {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 24px 0 16px 0;
}

.download__img {
  width: 200px;
}

@media only screen and (max-width: 768px) {
  .card__container {
    max-width: 300px;
    padding: 8px;
  }

  .card__logo {
    width: 100px;
  }

  .card__title {
    font-size: 24px;
    width: 200px;
    margin: 4px 0 24px 0;
    line-height: 24px;
  }

  .card__subtitle {
    font-size: 16px;
    margin: 8px 0 8px 0;
  }

  .download__img {
    width: 150px;
  }
}
